<template>
  <section class="chat-app-window d-flex flex-column" ref="refChatContainer">
    <div class="active-chat d-flex flex-column justify-content-between">
      <vue-perfect-scrollbar
        v-once
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area rounded-lg border-primary flex-fill"
        :style="{height: chatHeight}"
      >
        <div class="chats">
          <div
            v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
            :key="msgGrp.senderId + String(index)"
            class="chat"
            :class="{ 'chat-left': true }"
          >
            <!-- <div class="chat-avatar">
              <b-avatar
                size="36"
                class="avatar-border-2 box-shadow-1"
                variant="primary"
                :text="msgGrp.senderName && msgGrp.senderName.substring(0, 2)"
              />
            </div> -->
            <div class="chat-body">
              <div class="small">
                <span>{{msgGrp.senderName}}</span>
              </div>
              <div
                v-for="msgData in msgGrp.messages"
                :key="msgData.id"
                class="chat-content ml-0"
              >
                <div v-html="msgData.msg"></div>
              </div>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
      <!-- Message Input -->
      <b-form class="chat-app-form my-1 rounded-lg border-primary d-flex flex-row" @submit.prevent="sendMessage">
        <b-input-group class="flex-fill input-group-merge form-send-message">
          <b-form-input
            ref="refChatInputMessage"
            v-model="chatInputMessage"
            placeholder="Enter your message"
            debounce="500"
          />
          <div class="input-group-append">
            <b-button variant="primary" type="submit"> <feather-icon icon="SendIcon" /> </b-button>
          </div>
        </b-input-group>
      </b-form>
    </div>
  </section>
</template>

<script>
import { ref, computed, nextTick } from "@vue/composition-api";
import {
  BAvatar,
  BForm,
  BInputGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  name: "Chat",
  components: {
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,
    VuePerfectScrollbar,
    FeatherIcon,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
    height: {
      type: String,
      required: false,
      default: () => "430px"
    },
    profileUserAvatar: {
      type: String,
      required: false,
    },
    channelId: {
      type: String,
      required: true
    }
  },
  computed: {
    chatHeight() {
      // console.log('height', this.$refs)
      // console.log('parent height', this.$parent)
      // console.log('chatHeight:props', this.height)
      let _height = this.height || (this.$parent?.$el?.offsetHeight && this.$parent.$el.offsetHeight) + 'px'
      // console.log('_height', _height)
      return _height
    },
    chatInputMessage: {
      get() { return this.$store.state.messages.chatInputMessage },
      set(value) { this.$store.commit('messages/UPDATE_CHAT_INPUT_MESSAGE', value)}
    }
  },
  data() {
    return {
      // chatInputMessage: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 120,
      }
    }
  },
  methods: {
   sendMessage() {
    //  console.log(this.chatInputMessage)
     const payload = {channelId: this.channelId, message: this.chatInputMessage}
     this.$store.dispatch('wsSendMessage', payload)
     this.$store.dispatch('messages/updateChatInputMessage', '')
     this.focusChatInputMessage()
   }
  },
  mounted() {
    // this.$nextTick(() => {
    //       console.log(this.height, this.chatHeight)
    //   });
  },
  setup(props) {
    // console.log('props height', props.height)
    const refChatInputMessage = ref("");
    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null);
    const refChatContainer = ref(null);
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    };
    const focusChatInputMessage = () => {
      const focusEl = refChatInputMessage.value.$el || refChatInputMessage.value
      // console.log('focusEl', focusEl)
      focusEl.focus()
    };

    const formattedChatData = computed(() => {
      const contact = {
        id: "",
        avatar: "",
      };
      let chatLog = [];
      // console.log("props.chatData", props.chatData);
      if (props.chatData) {
        chatLog = props.chatData.filter(message => message.type === 'text');
      }

      const formattedChatLog = [];
      let chatMessageSenderId = chatLog[0] ? chatLog[0].fromId : undefined;
      let msgGroup = {
        sender: chatMessageSenderId,
        senderName: chatMessageSenderId ? chatLog[0].fromName : "",
        senderTitle: chatMessageSenderId ? chatLog[0].senderTitle : "",
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.fromId) {
          msgGroup.messages.push({
            msg: msg.content,
            time: msg.time,
          });
        } else {
          chatMessageSenderId = msg.fromId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.fromId,
            senderName: msg.fromName,
            senderTitle: msg.senderTitle,
            messages: [
              {
                msg: msg.content,
                time: msg.time,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });
      // Scroll to bottom
      nextTick(() => { scrollToBottomInChatLog() })
      nextTick(() => { focusChatInputMessage() })
      // console.log('formattedChatLog', props.chatData, formattedChatLog)
      return {
        formattedChatLog,
        contact,
      };
    });

    return {
      refChatInputMessage,
      refChatContainer,
      refChatLogPS,
      formattedChatData,
      scrollToBottomInChatLog,
      focusChatInputMessage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat-list.scss";
// .chat-app-form {
//   position: absolute;
//   bottom: 0;
//   margin-bottom: 10px;
//   width: 88%;
// }
</style>
